// Generated by Framer (74b1a33)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/S9VXUNg8e";

const cycleOrder = ["kwTwSOo0M", "yJy22_pgi", "fM_NX3ywl"];

const serializationHash = "framer-jVCQE"

const variantClassNames = {fM_NX3ywl: "framer-v-l6dhi1", kwTwSOo0M: "framer-v-egew0x", yJy22_pgi: "framer-v-13aocju"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "kwTwSOo0M", Phone: "fM_NX3ywl", Tablet: "yJy22_pgi"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, G6OCmkYyS: title ?? props.G6OCmkYyS ?? "About Us", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kwTwSOo0M"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, G6OCmkYyS, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kwTwSOo0M", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-egew0x", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"kwTwSOo0M"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-280f24fb-11f2-4a3c-a1bd-7c586a63e691, rgb(255, 255, 255))", ...style}} variants={{fM_NX3ywl: {backgroundColor: "rgb(255, 255, 255)"}}} {...addPropertyOverrides({fM_NX3ywl: {"data-framer-name": "Phone"}, yJy22_pgi: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><motion.div className={"framer-2hh9vk"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"c0lWpbiSo"}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-lou4uj"} data-styles-preset={"S9VXUNg8e"} style={{"--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-gdpscs, var(--token-6f40bf0f-ca5b-4e16-9e71-c8be36c74e29, rgb(0, 0, 0)))"}}>About Us</motion.h1></React.Fragment>} className={"framer-cbplcz"} data-framer-name={"Title"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"N6QOkopMZ"} style={{"--extracted-gdpscs": "var(--token-6f40bf0f-ca5b-4e16-9e71-c8be36c74e29, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={G6OCmkYyS} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jVCQE.framer-1q122xs, .framer-jVCQE .framer-1q122xs { display: block; }", ".framer-jVCQE.framer-egew0x { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 140px 50px 20px 50px; position: relative; width: 1200px; }", ".framer-jVCQE .framer-2hh9vk { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; max-width: 1240px; overflow: visible; padding: 0px; position: relative; width: 100%; z-index: 1; }", ".framer-jVCQE .framer-cbplcz { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jVCQE.framer-egew0x, .framer-jVCQE .framer-2hh9vk { gap: 0px; } .framer-jVCQE.framer-egew0x > *, .framer-jVCQE .framer-2hh9vk > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-jVCQE.framer-egew0x > :first-child, .framer-jVCQE .framer-2hh9vk > :first-child { margin-top: 0px; } .framer-jVCQE.framer-egew0x > :last-child, .framer-jVCQE .framer-2hh9vk > :last-child { margin-bottom: 0px; } }", ".framer-jVCQE.framer-v-13aocju.framer-egew0x { padding: 120px 30px 20px 30px; width: 810px; }", ".framer-jVCQE.framer-v-13aocju .framer-2hh9vk { max-width: 900px; }", ".framer-jVCQE.framer-v-l6dhi1.framer-egew0x { padding: 120px 20px 16px 20px; width: 390px; }", ".framer-jVCQE.framer-v-l6dhi1 .framer-2hh9vk { max-width: 400px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 215
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"yJy22_pgi":{"layout":["fixed","auto"]},"fM_NX3ywl":{"layout":["fixed","auto"]}}}
 * @framerVariables {"G6OCmkYyS":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKBjP1yR4c: React.ComponentType<Props> = withCSS(Component, css, "framer-jVCQE") as typeof Component;
export default FramerKBjP1yR4c;

FramerKBjP1yR4c.displayName = "Page Title";

FramerKBjP1yR4c.defaultProps = {height: 215, width: 1200};

addPropertyControls(FramerKBjP1yR4c, {variant: {options: ["kwTwSOo0M", "yJy22_pgi", "fM_NX3ywl"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, G6OCmkYyS: {defaultValue: "About Us", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerKBjP1yR4c, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})